//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

//overidding from file _layout.scss
.layout-sidebar{
    width: 220px;
}



//overidding from file _responsive.scss
@media (min-width: 992px) {
    .layout-wrapper {
        &.layout-static {
            .layout-main-container {
                margin-left: 220px;
            }
        }
    }
}
