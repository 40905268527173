.main_div {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    .fileupload {
        position: relative;

        color: white;
        > label {
            display: flex;
            gap: 2px;
        }
        > input {
            position: absolute;
            max-width: 0;
        }
    }

    .commentBox {
        max-width: 20rem;
        height: 3rem;
    }
}

.gallery {
    margin-top: 1rem;
    max-width: 80%;
    display: flex;
    gap: 1rem;
    overflow: auto;
    padding: 1rem;
}

.imagePeview {
    position: relative;
    > i {
        right: -8px;
        top: -8px;
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        font-size: 0.8rem;
        line-height: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;
    }
    .preview {
        width: 8rem;
        height: 6rem;
        object-fit: cover;
        object-position: top;
        // width: 100%;
    }
}
