.empty_message_block {
    margin-block: 32px;
    color: rgba(0, 0, 0, 0.25);
    margin-inline: 8px;
    font-size: 14px;
    line-height: 1.5714285714285714;
    text-align: center;

    .empty_image {
        height: 40px;

        svg {
            max-width: 100%;
            height: 100%;
            margin: auto;
        }
    }

    .message {
        margin: 3px;
    }
}