@import "primeflex/primeflex.scss";

:root {
    --red-500: #e24c4c
}

* {
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        background: #bababa;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    width: 100%;
}

.p-datatable .p-datatable-tbody>tr>td {
    padding: 0.3rem 1rem;
}

.p-datatable .p-datatable-thead>tr>th {
    padding: 0.7rem 1rem;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Adjust minmax for column width */
    grid-gap: 20px;
}

.p-tooltip .p-tooltip-text {
    font-size: 80%;
}

.p-selectbutton>div {
    font-size: 0.775rem;
    padding: 0.45625rem 1rem;
}

.text-red-600 {
    color: red !important
}

.p-column-filter-clear-button {
    display: none;
    max-width: 0;
    overflow: hidden;
}

tr[style] {
    td {
        width: inherit;
    }
}

.p-fieldset-legend {
    a {

        padding: 0.25rem !important;
    }
}

.border_less_input {
    input {

        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
        border-radius: 0;
        padding: 0 0.5rem 0.25rem 0.5rem;
        font-size: 0.9rem;

        &:focus {
            outline: none;
            box-shadow: none !important
        }
    }

    .p-badge {
        position: absolute;
        top: 2px;
        left: 0px;
        width: 1.5rem;
        height: 1.5rem;
        font-size: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        ~input {
            padding-left: 1.35rem;
        }
    }
}

.small-button {
    padding: 0.5rem !important;
    font-size: 0.8rem !important;
    max-width: 5.5rem !important;
    margin-inline-start: 1.25rem;
    position: relative;
}

.override-button {
    @extend .small-button;
    top: -0.6rem
}

.p-icon.p-datatable-reorderablerow-handle {
    cursor: pointer;
}

// tr[data-pc-section="rowexpansion"]{
//     >td{
//         padding: 0 !important
//     }
// }

.component-datatabale {
    td {
        padding: 0rem 0.5rem;
    }

    .p-inputtext-sm {
        padding: 0.25625rem 0.65625rem;

        &.p-dropdown {
            height: 26px;
            display: flex;
            align-items: center;
        }
    }
}

.bounce {
    animation: animate 1s ease;
}

@keyframes animate {
    30% {
        transform: scale(1.2);
    }

    40%,
    60% {
        transform: rotate(-20deg) scale(1.2);
    }

    50% {
        transform: rotate(20deg) scale(1.2);
    }

    70% {
        transform: rotate(0deg) scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.add-border-in-list {
    li {
        border-bottom: 2px solid #f8f9fa !important;
        padding: 0.35rem 1.25rem !important;
    }
}

.table-button {
    padding: 6px !important;
    width: 30px !important;

    .p-button-icon {
        font-size: 1.2rem !important;
    }
}

.collapse-container {
    margin-top: 1rem;
    overflow: hidden;
    transition: height 0.3s ease-out;

    label {
        font-weight: 600;
    }

    &.collapsed {
        height: 0;
        transition: 0.3s ease-in-out 0s;
    }
}

.tag-dropdown {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;

    .p-dropdown-label,
    .p-dropdown-trigger {
        padding: 0 !important;
        color: #ffffff !important;
        font-size: 0.75rem;
        font-weight: 700;
    }

    .p-dropdown-trigger {
        width: 1.5rem !important;

        svg {
            width: 10px;
            height: 10px;
        }
    }

}

.toggle-container {
    position: relative;


    .content {
        /* Set a maximum height for the content */
        overflow: hidden;
        transition: max-height 0.5s ease;
        /* Smooth transition effect */
    }

    .collapsed {
        max-height: 0;
        /* Collapse the content by setting max-height to 0 */
    }
}
.layout-topbar-menu{
.p-tooltip-target-wrapper{

    display: block;
    width: fit-content;
    button{
        margin-left: 0 !important;
    }
}}