.p-fieldset-legend {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding: 0.4rem 0.5rem !important;
    a,
    .p-fieldset-legend-text {
        width: 100%;
    }
}
.p-fieldset-content {
    padding: 0.5rem 1rem !important;
}
.action-button {
    width: 1.9rem !important;
    height: 1.9rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    span {
        font-size: 0.8rem;
        line-height: normal;
    }
}
